import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // useLocation 임포트 추가
import { Admin, NotFound, Form } from './pages';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
