import React, { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import {
  TextField,
  Button,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Container,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from '@mui/material';

const formFields = [
  {
    label: '기업명',
    name: 'companyName',
    type: 'text',
    placeholder: '마케팅',
    required: true,
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    helperText: '',
  },
  {
    label: '연락처',
    name: 'contact',
    type: 'text',
    placeholder: '01012345678',
    required: true,
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    helperText: '',
  },
  {
    label: '카카오톡 ID 또는 이메일',
    name: 'sns',
    type: 'text',
    placeholder: '톡 ID 또는 abc@example.com',
    required: false,
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    helperText: '',
  },
  {
    label: '성함',
    name: 'name',
    type: 'text',
    placeholder: '홍길동',
    required: true,
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    helperText: '',
  },
  {
    label: '상품 URL (스토어URL, 자사몰URL)',
    name: 'brandUrl',
    type: 'text',
    placeholder: 'https://smartstore.naver.com/yourBrand',
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    helperText: '',
  },
  {
    label: '개인정보 수집 및 이용동의',
    name: 'consentToCollectPersonalInfo',
    type: 'checkbox',
    gridProps: { xs: 12, sm: 12, md: 12 },
    error: false,
    required: true,
    helperText: '',
  },
];

const CustomLabel = ({ label, required }) => (
  <Typography variant="body1" sx={{ mb: 1 }}>
    {label}
    {required && <span style={{ color: 'red' }}> *</span>}
  </Typography>
);

const iniObj = formFields.reduce((acc, field) => {
  acc[field.name] = field.type === 'checkbox' ? false : '';
  return acc;
}, {});

const MarketingForm = () => {
  const [fields, setFields] = useState(formFields);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(iniObj);

  const inputRefs = useRef({});
  const postData = useMutation((data) =>
    axios.post(
      'https://9dja9c2bs6.execute-api.ap-northeast-2.amazonaws.com/prod/form',
      data
    )
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    let updatedFields = [...fields];

    updatedFields.forEach((field) => {
      const value = formValues[field.name];
      field.error = false;
      field.helperText = '';

      if (field.required && !value) {
        isValid = false;
        field.error = true;
        field.helperText = `${field.label}는 필수 입력 항목입니다.`;
        if (inputRefs.current[field.name]) {
          inputRefs.current[field.name].focus();
        }
      } else if (
        field.name === 'contact' &&
        value &&
        !/^\d{3}\d{4}\d{4}$/.test(value)
      ) {
        isValid = false;
        field.error = true;
        field.helperText = '연락처는 01000000000 형식이어야 합니다.';
        if (inputRefs.current[field.name]) {
          inputRefs.current[field.name].focus();
        }
      } else if (
        field.name === 'email' &&
        value &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
      ) {
        isValid = false;
        field.error = true;
        field.helperText = '유효한 이메일 주소를 입력해주세요.';
        if (inputRefs.current[field.name]) {
          inputRefs.current[field.name].focus();
        }
      }
    });

    setFields(updatedFields);

    if (isValid) {
      postData.mutate(formValues);
      alert(
        '접수 완료되었습니다. 영업일 기준 24시간 이내에 담당자 확인 후 연락드리겠습니다. ^^'
      );
      setFormValues(iniObj);
      // Add your form submission logic here
    } else {
      console.log('Validation errors:', updatedFields);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 5 }}>
        <Grid container spacing={3}>
          {fields.map((field, index) => (
            <Grid item {...field.gridProps} key={index}>
              {field.type !== 'checkbox' && (
                <>
                  <CustomLabel label={field.label} required={field.required} />
                  {field.type === 'text' && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={field.placeholder}
                      required={field.required}
                      name={field.name}
                      value={formValues[field.name]}
                      onChange={handleChange}
                      inputRef={(el) => (inputRefs.current[field.name] = el)}
                      error={field.error}
                      helperText={field.error ? field.helperText : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  {field.type === 'textarea' && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={4}
                      placeholder={field.placeholder}
                      required={field.required}
                      name={field.name}
                      value={formValues[field.name]}
                      onChange={handleChange}
                      inputRef={(el) => (inputRefs.current[field.name] = el)}
                      error={field.error}
                      helperText={field.error ? field.helperText : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  {field.type === 'select' && (
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      required={field.required}
                      name={field.name}
                      value={formValues[field.name] || ''}
                      onChange={handleChange}
                      inputRef={(el) => (inputRefs.current[field.name] = el)}
                      error={field.error}
                      helperText={field.error ? field.helperText : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <Typography sx={{ color: '#aaa' }}>
                          {field.placeholder}
                        </Typography>
                      </MenuItem>
                      {field.options &&
                        field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </>
              )}
              {field.type === 'checkbox' && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={field.name}
                        color="primary"
                        checked={formValues[field.name]}
                        onChange={handleChange}
                      />
                    }
                    label={field.label}
                  />

                  <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    onClick={handleClickOpen}
                  >
                    자세히 보기
                  </Link>
                  {field.error && (
                    <Typography color="error" variant="body2">
                      {field.helperText}
                    </Typography>
                  )}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>개인정보 처리방침</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Typography>
                          자사의 개인정보 수집 이용 목적은 다음과 같습니다.{' '}
                          <br />
                          내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기
                          바랍니다.
                          <br />
                          <br />
                          <Typography component={'span'} fontWeight={'bold'}>
                            서비스의 상담 및 서비스 안내 기타 이와 관련된
                            목적으로{' '}
                          </Typography>
                          <br />
                          기업명, 연락처의 필수 항목과 메신저 ID, 이메일, 성함,
                          스토어URL 같은 선택 항목을 수집하며
                          <Typography component={'span'} fontWeight={'bold'}>
                            {' '}
                            수집일로부터 3개월{' '}
                          </Typography>
                          까지 보유 및 이용합니다. <br /> <br /> 수집 근거는
                          개인정보 보호법 제15조 제1항이며, 이용자의 요청 시
                          언제든지 철회 가능합니다. 귀하는 위와 같이 개인정보를
                          수집·이용하는데 동의를 거부할 권리가 있습니다. 자사는
                          귀하가 동의한 범위를 초과하여 개인정보를 이용하거나 제
                          3자에게 제공 또는 공개하지 않습니다. 필수 수집 항목에
                          대한 동의를 거절하는 경우 서비스 이용이 제한 될 수
                          있습니다.
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        닫기
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                py: 2,
                bgcolor: '#1534bb',
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
              onClick={handleSubmit}
            >
              상담신청
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default MarketingForm;
