import { createTheme } from '@mui/material/styles';
import { color } from './color';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1534bb',
    },
    secondary: {
      main: '#ffcd49',
    },
    error: {
      main: color.common_red_01,
      dark: color.common_red_02,
      contrastText: color.white,
    },
    info: {
      main: color.common_gray_02,
      dark: color.common_gray_01,
      contrastText: color.white,
    },
  },

  typography: {
    fontFamily: ['Pretendard', 'Noto Sans Ko', 'Trebuchet MS'].join(','),
    letterSpacing: '-0.8px', // 원하는 letterSpacing 값으로 설정
    lineHeight: 1.2, // 원하는 lineHeight 값으로 설정
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: color?.common_new_blue_01,
              borderRadius: '8px',
            },
            '& input.Mui-disabled': {
              background: color?.common_gray_04,
              borderRadius: '8px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },

            // '&:hover fieldset': {
            //   borderColor: color?.common_new_blue_01,
            //   borderRadius: '8px',
            // },
            ' fieldset': {
              borderRadius: '8px',
            },
          },
        },
      },
    },
  },
});

export default theme;
